$('.main-slider').slick({
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  adaptiveHeight: false,
  prevArrow: '<button type="button" aria-label="Предыдущий слайд" class="main-slider__btn main-slider__btn_prev"><img src="img/prev.svg" alt="<"></button>',
  nextArrow: '<button type="button" aria-label="Следующий слайд" class="main-slider__btn main-slider__btn_next"><img src="img/next.png" alt=">"></button>'
});
$('.about-slider').slick({
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  adaptiveHeight: false,
  prevArrow: '<button type="button" aria-label="Предыдущий слайд" class="main-slider__btn main-slider__btn_prev"><img src="img/prev.svg" alt="<"></button>',
  nextArrow: '<button type="button" aria-label="Следующий слайд" class="main-slider__btn main-slider__btn_next"><img src="img/next.png" alt=">"></button>'
});

// Смена стрелок на кнопке при наведении
// главный блок
$('.main-slider__btn_prev').mouseover(function () {
  $(this).children('img').attr('src', 'img/prev-act.png');
});
$('.main-slider__btn_prev').mouseout(function () {
  $(this).children('img').attr('src', 'img/prev.svg');
});
$('.main-slider__btn_next').mouseover(function () {
  $(this).children('img').attr('src', 'img/next-act.png');

});
$('.main-slider__btn_next').mouseout(function () {
  $(this).children('img').attr('src', 'img/next.png');
});

// Смена кнопок при наведении в отзывах
$('.mean__btn_prev').mouseover(function () {
  $(this).children('img').attr('src', 'img/prev-act.png');
});
$('.mean__btn_prev').mouseout(function () {
  $(this).children('img').attr('src', 'img/prev.svg');
});
$('.mean__btn_next').mouseover(function () {
  $(this).children('img').attr('src', 'img/next-act.png');

});
$('.mean__btn_next').mouseout(function () {
  $(this).children('img').attr('src', 'img/next.png');
  // $(this).children('img').css('transform', 'none');
});


$('.usluga__btn').mouseover(function () {
  $(this).children('img').attr('src', 'img/left-arrow-chevron_white.png');
});
$('.usluga__btn').mouseout(function () {
  $(this).children('img').attr('src', 'img/left-arrow-chevron.png');
});


$('.means-items').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.slider-nav',
  adaptiveHeight: true,

});
$('.slider-nav').slick({
  centerMode: true,
  centerPadding: '0px',
  slidesToShow: 3,
  variableWidth: true, //
  slidesToScroll: 1,
  asNavFor: '.means-items',
  dots: false,
  centerMode: true,
  focusOnSelect: true,
  prevArrow: $('.mean__btn_prev'),
  nextArrow: $('.mean__btn_next'),
});


//F.A.Q
$('.faq-item__li_descr').hide();
$('.faq-item__li_question').click(function () {
  var $answer = $(this).next('.faq-item__li_descr');
  if ($answer.is(':hidden')) {
    $answer.slideDown();
    $(this).addClass('faq-item__li_question-close');
  } else {
    $answer.slideUp();
    $(this).removeClass('faq-item__li_question-close');
  }
});

// Политика конфиденциальности 
$('.agree-check__btn').mouseover(function () {
  $(this).next('.agree-check__btn_ok').css('border', '1px solid #6ca845');
})
$('.agree-check__btn').mouseout(function () {
  if ($('.agree-check__btn').is((':checked'))) {

  } else {
    $(this).next('.agree-check__btn_ok').css('border', '1px solid #101111');
  }
})

$('.agree-check__btn').click(function () {
  if ($('.agree-check__btn').is(':checked')) {
    $(this).next('.agree-check__btn_ok').addClass('checker');
  } else {
    $(this).next('.agree-check__btn_ok').removeClass('checker');

  }
})

//Плавный скролл по якорным ссылкам
$(function(){
  $('a[href^="#"]').on('click', function(event) {
    event.preventDefault();    
    if($(this).attr('data-plane') === 'link'){
      var sc = $(this).attr("href"),
      dn = $(sc).offset().top;
  $('html, body').animate({scrollTop: dn}, 1200);
    }
  
  });
});

//Появление шапки при скролле
$(function(){$(window).scroll(function(){200/* Изменять значение слева */<=$(this).scrollTop()?$(".header-fixed").addClass("header-fixed_active"):$(".header-fixed").removeClass("header-fixed_active")})})

$(".type-tel").mask("+7 (999) 999-99-99");

$("form").submit(function() {
    var th = $(this);
    $.ajax({
        type: "POST",
        url: "php/mail.php",
        data: th.serialize()
    }).done(function() {
        $("#popup").modal("hide");
        $("#popup-thanks").modal("show");
    });
    return false;
});